interface ColorOptions {
	saturation?: number;
	lightness?: number;
	alpha?: number;
	softness?: number;
	hueStart?: number;
}

class ColorService {
	static generateRandomRGBA(alpha = 1) {
		const red = Math.floor(Math.random() * 256);
		const green = Math.floor(Math.random() * 256);
		const blue = Math.floor(Math.random() * 256);

		return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
	}

	/**
	 * Generates an array of HSLA color strings.
	 * Colors are designed to be pastel and contrasting.
	 *
	 * @param count - The number of colors to generate.
	 * @param options - Optional settings to customize color generation.
	 * @param options.saturation - Color saturation percentage (0-100). Higher values produce more vibrant colors. Default is 50.
	 * @param options.lightness - Color lightness percentage (0-100). Higher values produce lighter colors. Default is 75.
	 * @param options.alpha - Color opacity (0-1). Lower values produce more transparent colors. Default is 1.
	 * @param options.softness - Softness factor for pastel effect (0-1). Higher values produce softer, more pastel-like colors. Default is 0.3.
	 * @param options.hueStart - Starting hue value (0-1). Determines the color of the first generated color. Default is random.
	 * @returns An array of HSLA color strings.
	 *
	 * @example
	 * // Generate 5 default pastel colors
	 * const colors = ColorService.generateChartColors(5);
	 *
	 * @example
	 * // Generate 3 more vibrant pastel colors
	 * const vibrantColors = ColorService.generateChartColors(3, {
	 *   saturation: 70,
	 *   softness: 0.2
	 * });
	 *
	 * @example
	 * // Generate 4 soft, light pastel colors with slight transparency
	 * const softColors = ColorService.generateChartColors(4, {
	 *   lightness: 85,
	 *   softness: 0.5,
	 *   alpha: 0.8
	 * });
	 */
	static generateHSLAColors(count: number, options: ColorOptions = {}): string[] {
		const { saturation = 50, lightness = 75, alpha = 1, softness = 0.3, hueStart = Math.random() } = options;

		const goldenRatioConjugate = 0.618033988749895;
		let hue = hueStart;

		return Array.from({ length: count }, () => {
			hue += goldenRatioConjugate;
			hue %= 1;

			const hslHue = Math.floor(hue * 360);
			const adjustedLightness = lightness + (100 - lightness) * softness;

			return `hsla(${hslHue}, ${saturation}%, ${adjustedLightness}%, ${alpha})`;
		});
	}

	static lightenColor(color, percent) {
		const num = parseInt(color.slice(1), 16);
		const amt = Math.min(255, Math.round(255 * (percent / 100)));
		const R = Math.min(255, (num >> 16) + amt);
		const G = Math.min(255, ((num >> 8) & 0x00ff) + amt);
		const B = Math.min(255, (num & 0x0000ff) + amt);
		return '#' + (((R & 0xff) << 16) | ((G & 0xff) << 8) | (B & 0xff)).toString(16).padStart(6, '0').toUpperCase();
	}

	static darkenColor(color, percent) {
		const num = parseInt(color.slice(1), 16);
		const amt = Math.round(2.55 * percent);
		const R = Math.max(0, (num >> 16) - amt);
		const G = Math.max(0, ((num >> 8) & 0x00ff) - amt);
		const B = Math.max(0, (num & 0x0000ff) - amt);
		return '#' + (((R & 0xff) << 16) | ((G & 0xff) << 8) | (B & 0xff)).toString(16).padStart(6, '0').toUpperCase();
	}

	static opacityColor(color, opacity) {
		const num = parseInt(color.slice(1), 16);
		const R = num >> 16;
		const G = (num >> 8) & 0x00ff;
		const B = num & 0x0000ff;
		return `rgba(${R},${G},${B},${opacity})`;
	}

	static hueShift(color, degree) {
		const hsl = ColorService.rgbToHsl(color);
		hsl[0] = (hsl[0] + degree) % 360;
		return ColorService.hslToRgb(hsl);
	}

	static desaturateColor(color, percent) {
		const hsl = ColorService.rgbToHsl(color);
		hsl[1] = Math.max(0, hsl[1] - percent);
		return ColorService.hslToRgb(hsl);
	}

	static rgbToHsl(color) {
		const r = parseInt(color.slice(1, 3), 16) / 255;
		const g = parseInt(color.slice(3, 5), 16) / 255;
		const b = parseInt(color.slice(5, 7), 16) / 255;

		const max = Math.max(r, g, b);
		const min = Math.min(r, g, b);
		let h;
		let s;
		const l = (max + min) / 2;

		if (max === min) {
			h = 0;
			s = 0;
		} else {
			const d = max - min;
			s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

			switch (max) {
				case r:
					h = (g - b) / d + (g < b ? 6 : 0);
					break;
				case g:
					h = (b - r) / d + 2;
					break;
				case b:
					h = (r - g) / d + 4;
					break;
			}

			h /= 6;
		}

		return [h * 360, s * 100, l * 100];
	}

	static hslToRgb(hsl) {
		const h = hsl[0] / 360;
		const s = hsl[1] / 100;
		const l = hsl[2] / 100;
		let r;
		let g;
		let b;

		if (s === 0) {
			r = g = b = l;
		} else {
			const hue2rgb = (p, q, t) => {
				if (t < 0) t += 1;
				if (t > 1) t -= 1;
				if (t < 1 / 6) return p + (q - p) * 6 * t;
				if (t < 1 / 2) return q;
				if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
				return p;
			};

			const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
			const p = 2 * l - q;
			r = hue2rgb(p, q, h + 1 / 3);
			g = hue2rgb(p, q, h);
			b = hue2rgb(p, q, h - 1 / 3);
		}

		return `#${Math.round(r * 255)
			.toString(16)
			.padStart(2, '0')}${Math.round(g * 255)
			.toString(16)
			.padStart(2, '0')}${Math.round(b * 255)
			.toString(16)
			.padStart(2, '0')}`;
	}

	static convertColorToRgb(color: string): [number, number, number] {
		if (color.startsWith('#')) {
			let hex = color.slice(1);

			if (hex.length === 3) {
				hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
			}

			if (hex.length === 6) {
				const bigint = parseInt(hex, 16);
				return [
					(bigint >> 16) & 255, // Red
					(bigint >> 8) & 255, // Green
					bigint & 255, // Blue
				];
			}
		}

		if (color.startsWith('rgb')) {
			const match = color.match(/\d+/g);
			if (match && match.length >= 3) {
				return [
					parseInt(match[0], 10), // Red
					parseInt(match[1], 10), // Green
					parseInt(match[2], 10), // Blue
				];
			}
		}

		// Handle named colors
		// Create canvas and set 1 pixel of a color, than get the rgb color from imageData, letting browser handle color convert by itself
		const canvas = document.createElement('canvas');
		canvas.width = canvas.height = 1;
		const ctx = canvas.getContext('2d');
		ctx.fillStyle = color;
		ctx.fillRect(0, 0, 1, 1);
		const data = ctx.getImageData(0, 0, 1, 1).data;
		return [data[0], data[1], data[2]];
	}
}

export default ColorService;
