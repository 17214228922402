import { create } from 'zustand';

interface IEnv {
	cpool?: string;
	ccid?: string;
	rev?: string;
	arena?: string;
	c_region?: string;
	c_domain?: string;
	c_identities?: [string];
	c_identity_callback_url?: string;
	c_is_2fa_required?: boolean;
	c_is_remember_device?: boolean;
	color_accent?: string;
	color_shift?: string;
	avatar?: string;
	logo?: string;
}

interface IEnvStore {
	env: IEnv;
	loaded: boolean;
	error: string;
	setEnv: (env: IEnv) => void;
	setError: (error: string) => void;
	loadEnvironment: () => Promise<IEnv>;
}

export const useEnvironmentStore = create<IEnvStore>()((set, get) => ({
	env: {},
	loaded: false,
	error: '',
	setEnv: (env: IEnv) => set(() => ({ env, loaded: true })),
	setError: (error: string) => set(() => ({ error })),
	loadEnvironment: () =>
		fetch('/api/env')
			.then(async (response) => {
				if (!response.ok) {
					set({
						error:
							'The server is currently undergoing maintenance. Please try reloading the page in 1 minute, or contact support if the issue continues.',
					});
					return;
				}
				const env = (await response.json()) as IEnv;
				set({ env, loaded: true });
				return env;
			})
			.catch((error) => {
				console.error('Failed to load environment:', error);
				throw error;
			}),
}));

let environmentPromise: Promise<IEnv> | null = null;

export function getEnvironment(): Promise<IEnv> {
	if (!environmentPromise) {
		environmentPromise = useEnvironmentStore.getState().loadEnvironment();
	}
	return environmentPromise;
}
