import { h, type FunctionalComponent } from 'preact';
import IconLoading from '@icons/icon-loading.svg';

import './Loader.scss';

const Loader: FunctionalComponent<{ text?: string }> = ({ text }) => (
	<div className="custom-loader">
		<IconLoading className="icon-loading" />
		{text && <p>{text}</p>}
	</div>
);

export default Loader;
