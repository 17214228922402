import { h, type JSX, type FunctionalComponent, type ComponentChildren, Fragment } from 'preact';
import classNames from 'classnames';
import './Button.scss';

export interface IButtonProps extends Omit<JSX.HTMLAttributes<HTMLButtonElement>, 'loading' | 'icon'> {
	children?: ComponentChildren;
	className?: string;
	small?: boolean;
	light?: boolean;
	secondary?: boolean;
	link?: boolean;
	fullWidth?: boolean;
	loading?: boolean;
	icon?: JSX.Element;
}

const Button: FunctionalComponent<IButtonProps> = ({
	children = null,
	className = '',
	small = false,
	light = false,
	secondary = false,
	link = false,
	fullWidth = true,
	loading = false,
	icon = null,
	...props
}) => (
	<button
		{...props}
		className={classNames('button', {
			[className]: !!className,
			'full-width': fullWidth,
			small,
			light,
			secondary,
			link,
			loading,
			'with-icon': !!icon,
		})}
		onClick={!loading && props.onClick}
	>
		{loading && (
			<Fragment>
				<div className="inner-loader" />
			</Fragment>
		)}

		{icon && <div className="button-icon">{icon}</div>}

		<span className="button-text">{children}</span>
	</button>
);

export default Button;

export const ButtonSecondary: FunctionalComponent<IButtonProps> = (props) => (
	<Button {...props} secondary>
		{props.children}
	</Button>
);

export const ButtonLink: FunctionalComponent<IButtonProps> = (props) => (
	<Button {...props} link>
		{props.children}
	</Button>
);

export const ButtonLight: FunctionalComponent<IButtonProps> = (props) => (
	<Button {...props} light>
		{props.children}
	</Button>
);

export const ButtonSmall: FunctionalComponent<IButtonProps> = (props) => (
	<Button {...props} small>
		{props.children}
	</Button>
);
