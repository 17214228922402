import ColorService from '@services/ColorService';
import { getEnvironment } from '@services/EnvService';
import classNames from 'classnames';
import { h, type FunctionalComponent } from 'preact';
import { useEffect, useState } from 'preact/hooks';

import './ImageBackground.scss';

const BACKGROUND_IMAGE_DOMINANT_COLOR = '#139D7F';

interface IImageBackgroundProps {
	className?: string;
}

const ImageBackground: FunctionalComponent<IImageBackgroundProps> = ({ className }) => {
	const [diffHue, setDiffHue] = useState(0);

	const setupHue = async () => {
		const env = await getEnvironment();
		const accentColor = env?.color_accent;

		if (!accentColor) {
			return;
		}

		const [backgroundOriginalHue] = ColorService.rgbToHsl(BACKGROUND_IMAGE_DOMINANT_COLOR);

		const [accentColorHue] = ColorService.rgbToHsl(accentColor);

		setDiffHue(accentColorHue - backgroundOriginalHue);
	};

	useEffect(() => {
		setupHue();
	}, []);

	return <div className={classNames('image-background', className)} style={{ filter: `hue-rotate(${diffHue}deg)` }} />;
};

export default ImageBackground;
