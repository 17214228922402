import ColorService from './ColorService';

class CSSVariablesService {
	register(colors: { [key: string]: string }) {
		for (const color in colors) {
			console.log('registering color', color, colors[color]);
			window.CSS.registerProperty({
				name: '--' + color,
				syntax: '<color>',
				inherits: false,
				initialValue: colors[color],
			});
		}
	}

	update(colors: { [key: string]: string }) {
		for (const color in colors) {
			document.documentElement.style.setProperty('--' + color, colors[color]);
		}
	}

	buildColorScheme(colorAccent, isDay = false) {
		const colors = {
			white: isDay ? '#333333' : '#FFFFFF',
			black: isDay ? '#FFFFFF' : '#000000',
			grey: '#808080',
			red: '#FF4646',
			yellow: '#FF8539',
			green: '#009773',
			blue: '#0000FF',
			purple: '#800080',
			accent: colorAccent,
		};
		// var(--color-accent: adjust-color(var(--color-accent, $lightness: -10%, $hue: -5);
		const schemeBasic = {
			'color-white': colors.white,
			'color-black': colors.black,
			'color-grey': colors.grey,
			'color-red': colors.red,
			'color-yellow': colors.yellow,
			'color-green': colors.green,
			'color-blue': colors.blue,
			'color-purple': colors.purple,
			'color-accent': colors.accent,
			'color-white-2': ColorService.opacityColor(colors.white, 0.02),
			'color-white-5': ColorService.opacityColor(colors.white, 0.05),
			'color-white-10': ColorService.opacityColor(colors.white, 0.1),
			'color-white-20': ColorService.opacityColor(colors.white, 0.2),
			'color-white-50': ColorService.opacityColor(colors.white, 0.5),
			'color-white-65': ColorService.opacityColor(colors.white, 0.65),
			'color-white-80': ColorService.opacityColor(colors.white, 0.8),
			'color-accent-10': ColorService.opacityColor(colors.accent, 0.1),
			'color-accent-30': ColorService.opacityColor(colors.accent, 0.3),
			'color-accent-50': ColorService.opacityColor(colors.accent, 0.5),
			'color-accent-80': ColorService.opacityColor(colors.accent, 0.8),
			'color-black-20': ColorService.opacityColor(colors.black, 0.2),
			'color-black-50': ColorService.opacityColor(colors.black, 0.5),
			'color-green-20': ColorService.opacityColor(colors.green, 0.2),
			'color-green-30': ColorService.opacityColor(colors.green, 0.3),
			'color-yellow-20': ColorService.opacityColor(colors.yellow, 0.2),
			'color-red-20': ColorService.opacityColor(colors.red, 0.2),
			'color-grey-10': ColorService.opacityColor(colors.grey, 0.1),
			'color-grey-30': ColorService.opacityColor(colors.grey, 0.3),
			'color-accent-active': isDay
				? ColorService.darkenColor(colors.accent, 10)
				: ColorService.lightenColor(colors.accent, 25),
			'color-accent-light': ColorService.lightenColor(colors.accent, 35),
			'color-background-dark': isDay
				? ColorService.lightenColor(colors.accent, 25)
				: ColorService.darkenColor(colors.accent, 25),
			'color-desaturated': isDay
				? ColorService.lightenColor(ColorService.desaturateColor(colors.accent, 5), 50)
				: ColorService.darkenColor(ColorService.desaturateColor(colors.accent, 5), 50),
			'color-background-darken-desaturated': isDay
				? ColorService.lightenColor(ColorService.desaturateColor(ColorService.hueShift(colors.accent, 12), 15), 50)
				: ColorService.darkenColor(ColorService.desaturateColor(ColorService.hueShift(colors.accent, 12), 15), 50),
		};
		const darkShift = 10;
		const schemeDependent = {
			'color-accent-active-10': ColorService.opacityColor(schemeBasic['color-accent-active'], 0.1),
			'color-accent-active-30': ColorService.opacityColor(schemeBasic['color-accent-active'], 0.3),
			'color-accent-light-30': ColorService.opacityColor(schemeBasic['color-accent-light'], 0.3),
			'color-desaturated-start': isDay
				? ColorService.lightenColor(ColorService.desaturateColor(colors.accent, 10), 90 + darkShift)
				: ColorService.darkenColor(ColorService.desaturateColor(colors.accent, 10), 30 + darkShift),
			'color-desaturated-end': isDay
				? ColorService.lightenColor(ColorService.desaturateColor(colors.accent, 10), 70 + darkShift)
				: ColorService.darkenColor(ColorService.desaturateColor(colors.accent, 10), 70 + darkShift),
		};

		return { ...schemeBasic, ...schemeDependent };
	}
}

const CSSVariables = new CSSVariablesService();

export default CSSVariables;
